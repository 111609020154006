$colors-brand-primary: #3F51B5;
$colors-brand-secondary: #3F51B5;

$colors-background-gray: #f4f4f4;
$colors-background-gray-dark: #3F51B5;

$colors-light-gray: #6d6e71;
$colors-middle-gray: #3e4043;

$colors-text-dark: #262627;

$colors-error: #d40c27;
$colors-warning: #fa7910;
$colors-success: #06e08b;

// $navbar-height: 3.5rem;

:root {
    --colors-brand-primary: #{$colors-brand-primary};
    --colors-brand-secondary: #{$colors-brand-secondary};

    --colors-background-gray: #{$colors-background-gray};
    --colors-background-gray-dark: #{$colors-background-gray-dark};

    --colors-background-black: #242f3e;

    --colors-light-gray: #{$colors-light-gray};

    --colors-text-dark: #{$colors-text-dark};

    --colors-error: #{$colors-error};
    --colors-warning: #{$colors-warning};
    --colors-success: #{$colors-success};

    --navbar-height: 4rem;

    font-size: 13px;
}

// @media (prefers-color-scheme: dark) {
// :root {
//     --colors-background-gray: #{black};
// }
//   }

@media (min-width: 801px) {
    :root {
        font-size: 16px;
        --navbar-height: 3.5rem;
    }
}
